import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const FlaPage = () => (
    <Layout>
        <Seo title={'Finance & Leasing Association | Nemo Personal Finance'} description={'We are a member of the Finance and Leasing Association and follow its lending code. View details of how we lend online at Nemo Personal Finance.'} lang={'en-gb'} />
        <section className="oneCol blue cfx">
            <div className="bdr">
                <div className="bdrTop"></div>
                <div className="bdrMid cfx">
                    <div className="headingArea cfx">
                        <div className="bubble blue cfx cp">
                            <div className="inr">
                                <h1>FLA</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <div className="intro">
                            <p className="leading">Nemo is a full member of the FLA (Finance and Leasing Association).
                                The FLA is the leading trade association for the consumer mortgage, credit, motor
                                finance and asset finance sectors in the UK. The FLA strives to be acknowledged as the
                                trusted representative body for the business and consumer finance industries and
                                campaigns for best practice in lending and leasing.</p>
                        </div>
                    </div>
                    <p>The FLA Lending Code provides the consumer finance industry with standards of good practice and
                        intends to assure those obtaining finance from full members that they are borrowing from
                        reputable organisations. The FLA also operates a business finance Code which sets standards for
                        its members providing asset finance to businesses.</p>
                    <p>Membership to the FLA is conditional to companies meeting strict standards which are set out in
                        the FLA Lending Code 2017 and performance is monitored in line with the Code. </p>
                    <p>At the time when you took out your second charge mortgage from, you can be confident that our lending complied with the code. When servicing your second charge mortgage, Nemo observes the Code as follows:</p>
                    <ul>
                        <li>By acting fairly, reasonably and responsibly in all our dealings with our customers.</li>
                        <li>By treating all personal information as private and confidential and by running secure and
                            reliable systems.
                        </li>
                        <li>By not discriminating because of race, sex, disability, ethnic background or sexuality.</li>
                        <li>By helping when information and guidance is needed including explaining how our second
                            charge mortgages will affect customers’ finances.
                        </li>
                        <li>By giving customers information on the key features of their&nbsp;second charge
                            mortgage&nbsp;from Nemo.
                        </li>
                        <li>By checking all applications thoroughly prior to lending.</li>
                        <li>By explaining our procedures and timescales when handling complaints.</li>
                        <li>By treating customers who are in financial difficulty positively and sympathetically.</li>
                        <li>By dealing with customers who have health issues sympathetically.</li>
                    </ul>
                    <p>As Nemo is a full member of the FLA, our customers can be confident that we observe the code. If
                        you would like a copy of the FLA Lending code please click <a
                            href="http://www.fla.org.uk/index.php/consumer-information/lending-code/">here</a> to go to
                        the FLA website where it is possible to print a copy. Alternatively, please call us on&nbsp;0800
                        138 2935&nbsp;and we would be happy to send you a copy. </p>

                </div>
                <div className="bdrBot"></div>
            </div>
        </section>
    </Layout>
)

export default FlaPage;